require('sharer.js')

document.addEventListener('DOMContentLoaded', () => {
  function initMap() {
    setTimeout(() => {
      const mapElems = document.getElementsByClassName("google_map")
      Array.from(mapElems).forEach(element => {
        const mapData = element.dataset;
        console.log("testttintiitmapp")
        const location = { lat: parseFloat(mapData.latitude), lng: parseFloat(mapData.longitude) };

        const map = new google.maps.Map(element, {
          mapId: "360732fa56b71f77",
          zoom: 15,
          center: location,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false
        });

        const marker = new google.maps.Marker({
          position: location,
          map: map,
        });

      })
    }, 500);
  }

  window.initMap = initMap;
})
